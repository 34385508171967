<template>
    <div class="ql-container ql-snow">
        <div class="ql-editor">
            <div v-html="txt"></div>
        </div>
    </div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
export default {
    name: 'TxtView',
    props: {
        txt: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
.ql-container.ql-snow {
   

    border: none;
}

.ql-editor {
    text-align: center;
    height: auto;
}

.ql-container {
    /* 富文本默认字体大小 */
    font-size: 16px;
}
</style>
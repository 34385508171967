<template>
  <div class="box">
    <p class="total">评论 <span class="total-num">{{ total }}</span> </p>
    <div v-if="commentList.length != 0">
    <div class="comment-box" v-for="(item, index) in commentList" :key="item.id">
      <div class="avatar">
        <img :src="item.avatar" alt="">
      </div>
      <div class="comment-info">
        <p class="comment-name">{{ item.nickname }}</p>
        <p class="comment-txt">{{ item.txt }}</p>

        <el-collapse accordion v-if="item.replies">
          <el-collapse-item :title="'查看 ' + item.replies + ' 条回复'" :name="index">
            <div class="comment-box" v-for="j in item.replyList" :key="j.id">
              <div class="avatar">
                <img :src="j.avatar" alt="">
              </div>
              <div class="comment-info">
                <p class="comment-name">{{ j.replyCommentNickname ? j.nickname + ' 回复 ' + j.replyCommentNickname : j.nickname }}
                </p>
                <p class="comment-txt">{{ j.txt }}</p>
                <p class="comment-status">
                  <span>{{ formatDate(j.createTime, 'yyyy-MM-dd') }}</span>
                  <span><img src="~@/assets/images/zan.png" alt="">{{ j.ups }}</span>
                  <!-- <span><img src="~@/assets/images/comment.png" alt="">{{ j.replies }}</span> -->

                </p>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>

        <p class="comment-status">
          <span>{{ formatDate(item.createTime, 'yyyy-MM-dd') }}</span>
          <span><img src="~@/assets/images/zan.png" alt="">{{ item.ups }}</span>
          <span><img src="~@/assets/images/comment.png" alt="">{{ item.replies }}</span>

        </p>
      </div>
    </div>
    <div class="load-more" @click="moreComment" v-if="more">
      <span>
        查看更多评论
        <i class="el-icon-arrow-down"></i>
      </span>
    </div>
    <div class="load-more" @click="moreComment" v-else>
      <span>
        已加载全部评论
        <!-- <i class="el-icon-arrow-down"></i> -->
      </span>
    </div>
    </div>
    <el-empty v-else description="暂无评论"></el-empty>
  </div>
</template>

<script>
import { getComment } from "@/api/comment/comment";
export default {
  name: 'comment',
  props: ['id', 'contentType'],
  data() {
    return {
      commentList: [],
      replyList: [],
      total: null,
      more: false,
      queryParams: {
        pageNo: 1,//页码
        pageSize: 10,//每页条数
        contentId: this.id,
        contentType: this.contentType,
        parentCommentId: ''
      }
      // activeNames: ['5']
    }
  },
  created() {
    this.getList()
    // this.show_collapse()
  },
  methods: {
    moreComment() {
      this.queryParams.pageNo++
      this.getList()
    },
    getList() {
      getComment(this.queryParams).then(res => {
        // console.log(res);
        if (res.code === 0) {
          this.total = res.data.total
          res.data.list.forEach((item, index) => {
            // console.log(item.replies);
            if (item.replies) {
              getComment({ contentId: this.id, contentType: this.contentType, parentCommentId: item.id, pageSize: 100 }).then(j => {
                this.$set(res.data.list[index], 'replyList', j.data.list)
              })
            }
          })
          this.commentList = [...this.commentList, ...res.data.list]

          if (res.data.list.length < 10) {
            this.more = false
          } else {
            this.more = true
          }
        }
      })
    }
  },


}
</script>

<style lang="scss" scoped>
::v-deep .el-collapse-item__wrap {
  background-color: #f5f5f5;
  padding: 0 30px;
}

::v-deep .el-collapse {
  border: none;
}

::v-deep .el-collapse-item__header {
  padding: 0 30px;
  background-color: #f5f5f5;
}

.box {

  // display: ;
  .load-more {
    cursor: pointer;
    text-align: center;
    margin: 30px;

    // padding: 30px;
    span {
      padding: 10px 30px;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    // box-sizing: content-box;

    // margin:  0 auto;
  }

  .total {
    line-height: 100px;
    font-size: 30px;
    font-weight: bold;

    .total-num {
      font-weight: normal;
      color: #3A8DA6;
      font-size: 24px;
    }

  }

  .comment-box {
    margin-top: 20px;
    display: flex;


    .avatar {
      width: 50px;
      height: 50px;
      margin-right: 30px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

    }

    .comment-info {
      // padding: 30px 0;
      flex: 1;
      border-bottom: 1px dashed #ccc;

      .comment-name {
        font-size: 14px;
        line-height: 24px;
        font-weight: bold;
      }

      .comment-txt {
        font-size: 16px;
        line-height: 36px;
        font-weight: lighter;

      }

      .reply-box {
        padding: 30px;
      }

      .comment-status {
        font-size: 14px;
        color: #979797;
        margin: 20px 0;
        display: flex;
        align-items: center;


        span {
          display: inline-block;
          margin-right: 30px;
          display: flex;
          align-items: center;
          // width: 100px;
        }

        img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }


    }
  }
}</style>
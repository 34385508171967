<template>
    <div class="category-box">
        <div class="category" :style="{height:isDetail?'60px':'',width:isDetail?'150px':'',fontSize:isDetail?'20px':''}" @click="selectCategory(i)" :class="{ 'category-active': index == i }"
            v-for="(item, i) in  categorys " :key="i">
            {{ item.name }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Category',
    props: {
        isDetail:{
            type:Boolean,
            default:false
        },
        //菜单数组
        categorys: {
            type: Array
        },
        //当前菜单下标
        categoryIndex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            index: 0, //当前菜单下标
        }
    },
    methods: {
        /**
         * 选择分类
         */
        selectCategory(index) {
            if (index == this.index) {
                return
            }
            this.index = index
            this.$emit('change', this.index, this.categorys[index])
        }
    },
    created() {
        this.index = this.categoryIndex
    }

}
</script>

<style lang="scss" scoped>
.category-box {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .category {
        transition: .3s;
        width: 104px;
        height: 37px;
        background: url('../../assets/images/news-category-border.png') no-repeat center center scroll;
        background-size: contain;
        color: #979797;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        cursor: pointer;
    }

    .category-active {
        background: url('../../assets/images/news-category-border-active.png') no-repeat center center scroll;
        background-size: contain;
        font-weight: bold;
        color: #FFFFFF;
    }
}
</style>
<template>
    <el-breadcrumb class="app-breadcrumb" separator=">">
        <transition-group name="breadcrumb">
            <el-breadcrumb-item v-for="(item, index) in levelList" :key="index">
                <a @click.prevent="handleLink(item)">{{ item.title }}</a>
            </el-breadcrumb-item>
        </transition-group>
    </el-breadcrumb>
</template>

<script>
export default {
    name: 'Breadcrumb',
    props: {
        current: {
            type: Object
        }
    },
    data() {
        return {
            levelList: [
                {
                    title: '首页',
                    path: '/',
                }
            ]
        }
    },
    methods: {
        handleLink(item) {
            if (item.path) {
                this.$router.push(item.path)
            }
        }
    },
    created() {
        this.levelList.push({
            title: this.$route.matched[0].meta.title,
            path: this.$route.matched[0].path,
        })
        // console.log(this.current);
        if (this.current) {
            this.levelList.push({
                title: this.current.title,
                path: this.current.path,
            })
        }
    }

}
</script>

<style scoped>
.app-breadcrumb {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";

const Layout = () => import("../layout");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "/index",
        component: (resolve) => require(["@/views/home/index.vue"], resolve),
        name: "首页",
        meta: {
          title: "首页",
          hideHeader: true,
        },
      },
    ],
  },
  {
    path: "/kanfeiyi",
    name: "kanfeiyi",
    component: Layout,
    redirect: "/kanfeiyi/list",
    meta: {
      title: "看·非遗",
    },
    children: [
      {
        path: "list",
        component: (resolve) => require(["@/views/kanfeiyi/list.vue"], resolve),
        name: "newsList",
        meta: {
          title: "看·非遗",
        },
      },
      {
        path: "detail/:id",
        component: (resolve) =>
          require(["@/views/kanfeiyi/detail.vue"], resolve),
        name: "newsDetail",
        meta: {
          title: "看·非遗",
          contentType: 1,
        },
      },
    ],
  },
  {
    path: "/wanfeiyi",
    name: "wanfeiyi",
    component: Layout,
    redirect: "/wanfeiyi/list",
    meta: {
      title: "玩·非遗",
    },
    children: [
      {
        path: "list",
        component: (resolve) => require(["@/views/wanfeiyi/list.vue"], resolve),
        name: "wanList",
        meta: {
          title: "玩·非遗",
        },
      },
      {
        path: "detail/:id",
        component: (resolve) =>
          require(["@/views/wanfeiyi/detail.vue"], resolve),
        name: "wanDetail",
        meta: {
          title: "玩·非遗",
          contentType: 4,
        },
      },
    ],
  },
  {
    path: "/shangfeiyi",
    name: "shangfeiyi",
    component: Layout,
    redirect: "/shangfeiyi/list",
    meta: {
      title: "赏·非遗",
    },
    children: [
      {
        path: "list",
        component: (resolve) =>
        require(["@/views/shangfeiyi/list.vue"], resolve),
        name: "enjoyList",
        meta: {
          title: "赏·非遗",
        },
      },
      {
        path: "project-detail/:id",
        component: (resolve) =>
          require(["@/views/shangfeiyi/project-detail.vue"], resolve),
        name: "projectDetail",
        meta: {
          title: "非遗项目",
          contentType:2,
        },
      },
      {
        path: "people-detail/:id",
        component: (resolve) =>
          require(["@/views/shangfeiyi/people-detail.vue"], resolve),
        name: "peopleDetail",
        meta: {
          title: "非遗传承人",
          contentType: 3,
        },
      },
    ],
  },
  {
    path: "/bofeiyi",
    name: "bofeiyi",
    component: Layout,
    redirect: "/bofeiyi/list",
    meta: {
      title: "播·非遗",
    },
    children: [
      {
        path: "list",
        component: (resolve) => require(["@/views/bofeiyi/list.vue"], resolve),
        name: "liveList",
        meta: {
          title: "播·非遗",
        },
      },
      {
        path: "detail/:id",
        component: (resolve) => require(['@/views/bofeiyi/detail.vue'], resolve),
        name: "liveDetail",
        meta: {
          title: "播·非遗",
          contentType: 5,
        },
      },
    ],
  },
  {
    path: "/xuefeiyi",
    name: "xuefeiyi",
    component: Layout,
    redirect: "/xuefeiyi/videoList",
    meta: {
      title: "学·非遗",
    },
    children: [
      {
        path: "videoList",
        component: (resolve) =>
          require(["@/views/xuefeiyi/videoList.vue"], resolve),
        name: "videoList",
        meta: {
          title: "视频",
        },
      },
      {
        path: "videoDetail/:id",
        component: (resolve) =>
          require(["@/views/xuefeiyi/videoDetail.vue"], resolve),
        name: "videoDetail",
        meta: {
          title: "视频详情",
          contentType: 6,
        },
      },
      {
        path: "picsList",
        component: (resolve) =>
          require(["@/views/xuefeiyi/picsList.vue"], resolve),
        name: "picsList",
        meta: {
          title: "非遗作品",
        },
      },
      {
        path: "picsDetail/:id",
        component: (resolve) =>
          require(["@/views/xuefeiyi/picsDetail.vue"], resolve),
        name: "picsDetail",
        meta: {
          title: "非遗作品详情",
          contentType: 8,
        },
      },
      {
        path: "audioList",
        component: (resolve) =>
          require(["@/views/xuefeiyi/audioList.vue"], resolve),
        name: "audioList",
        meta: {
          title: "音频",
        },
      },
      {
        path: "audioDetail/:id",
        component: (resolve) =>
          require(["@/views/xuefeiyi/audioDetail.vue"], resolve),
        name: "audioDetail",
        meta: {
          title: "音频详情",
          contentType: 7,
        },
      },
    ],
  },
  {
    path: "/goufeiyi",
    name: "goufeiyi",
    component: Layout,
    redirect: "/goufeiyi/list",
    meta: {
      title: "购·非遗",
    },
    children: [
      {
        path: "list",
        component: (resolve) =>
          require(["@/views/goufeiyi/list.vue"], resolve),
        name: "buyList",
        meta: {
          title: "购·非遗",
        },
      },
      {
        path: "detail/:id",
        component: (resolve) =>
          require(["@/views/goufeiyi/detail.vue"], resolve),
        name: "audioDetail",
        meta: {
          title: "商品详情",
          contentType: 9,
        },
      },
    ]

  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <header>
    <div class="logo">
      <img src="https://oss.culturalcloud.net/furong/202310/101556298vu9.png" alt="">
    </div>
    <div class="tabs">
      <div class="item" :class="{ 'active': $route.path.includes(item.path) }" v-for="(item, index) in tabs" :key="index">
        <div class="item-title">
          <router-link :to="item.path">
            {{ item.name }}
          </router-link>
        </div>
        <div class="triangle"></div>
      </div>
    </div>
    <!-- <div style="margin-left: auto;display: none;" class="">111</div> -->

  </header>
</template>

<script>
export default {
  name: 'r-header',
  data() {
    return {
      tabs: [
        { name: '首   页', path: '/index' },
        { name: '看·非遗', path: '/kanfeiyi' },
        { name: '赏·非遗', path: '/shangfeiyi' },
        { name: '玩·非遗', path: '/wanfeiyi' },
        { name: '播·非遗', path: '/bofeiyi' },
        { name: '学·非遗', path: '/xuefeiyi' },
        { name: '购·非遗', path: '/goufeiyi' }
      ]
    }
  },
  methods: {
    goto(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;

  height: 80px;
  background: url('https://oss.culturalcloud.net/furong/202310/101554035lwv.png') center;

  .logo {
    position: absolute;
    left: 30px;
    // float: left;
    // margin-right: auto;
  }

  .tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      text-align: center;
      line-height: 80px;
      width: 150px;
      height: 80px;
      position: relative;
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-family: XiaoWei-Regular;

      .triangle {
        position: absolute;
        opacity: 0;
        transform: translate(-50%, 30%);
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }

      a {
        color: #181B1C;
      }

      a:hover {
        color: #3A8DA6;
      }
    }

    .item.active {
      .item-title {
        border-bottom: 2px solid #3A8DA6;
      }

      .triangle {
        position: absolute;
        transition: .3s;
        opacity: 1;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid #3A8DA6;
        z-index: 9;
      }

      .triangle::before {
        content: "";
        position: absolute;
        top: 2px;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-bottom: 8px solid #ffffff;
      }

      .triangle::after {
        content: "";
        position: absolute;
        top: 5px;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-bottom: 5px solid #3A8DA6;
      }
    }
  }
}
</style>
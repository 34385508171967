import router from './router'
import store from './store'
import {
	Message
} from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({
	showSpinner: false
})


router.beforeEach((to, from, next) => {
	NProgress.start()
	document.title = process.env.VUE_APP_TITLE + " - " + (to.meta.title || "")
	NProgress.done()
	next()
})

router.afterEach(() => {
	NProgress.done()
})
import request from '@/utils/request'


// 获得视评论分页
export function getComment(query) {
    return request({
        url: '/ich/comment/list',
        method: 'get',
        params: query
    })
}
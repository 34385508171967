import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import 'vue-social-share/dist/client.css';
import './assets/css/global.css'
import router from './router'
import store from './store'
import cache from '@/utils/cache'
import modal from '@/utils/modal'
import '@/permission' // permission control
import { parseTime } from '@/utils/common'
import Category from "@/components/Category";
import Pagination from "@/components/Pagination";
import Breadcrumb from "@/components/Breadcrumb";
import Comment from "@/components/Comment";
import List from "@/components/List";
import TxtView from "@/components/TxtView";
import MyShare from "@/components/MyShare";


import VueFullpage from 'vue-fullpage.js'
Vue.use(VueFullpage)


// 缓存对象
Vue.prototype.$cache = cache
// 模态框对象
Vue.prototype.$modal = modal
Vue.prototype.formatDate = parseTime

Vue.config.productionTip = false

Vue.use(ElementUI);

Vue.component('Category', Category)
Vue.component('Pagination', Pagination)
Vue.component('Breadcrumb', Breadcrumb)
Vue.component('List', List)
Vue.component('TxtView', TxtView)
Vue.component('MyShare', MyShare)
Vue.component('Comment', Comment)


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
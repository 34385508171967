<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <r-header v-show="!$route.meta.hideHeader"></r-header>
    <router-view :header="header"></router-view>

  </div>
</template>
<script>
import RHeader from '@/components/Header.vue'
export default {
  components: {
    RHeader
  },
  data() {
    return {
      headerShow: true,
    }
  },
  methods: {
    header(show) {
      this.headerShow = show
    }
  },
  watch: {
    // 监听 $route 属性的变化
  },
  created() {
  }
}
</script>

<style lang="scss">
@import "assets/style/reset.scss";
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>

<template>
    <div class="list-box">
        <div class="list-item" v-for="(item, index) in list" :key="index" @click="toDetail(item)">
            <div class="item-image-box" :style="{ height: imgHeight }">
                <img class="item-image" :src="item.titleImg" alt="">
                <!-- 标题图遮罩插槽（放播放按钮、状态等） -->
                <div style="position: absolute;top: 10px;left: 10px;" >   
                 <slot  name="status" :item="item"></slot>
                </div>
                <slot name="title-img-mask" :item="item"></slot>
            </div>
            <div class="item-info">
                <div class="item-title">{{ item.title }}</div>
                <!-- 其他信息插槽（放活动时间、活动地点、简介等） -->
                <slot :row="item"></slot>
            </div>
            <!-- 小三角 -->
            <div class="triangle"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'List',
    props: {
        //列表数据
        list: {
            require: true,
            type: Array
        },
        imgHeight: {
            type: String,
            default: '280px'
        }
    },
    methods: {
        toDetail(item) {
            this.$emit('toDetail', item)
        }
    }
}
</script>

<style lang="scss" scoped>
.list-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;

    .list-item {
        width: 31.6%;
        margin-bottom: 30px;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        .item-image-box {
            width: 100%;
            height: 280px;
            position: relative;

            .item-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .item-info {
            padding: 20px;
            background-color: rgba(230, 230, 230, 0.295);
            border: 1px solid #F4F5F7;

            .item-title {
                color: #000000;
                font-size: 18px;
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding: 5px 0;
            }

            div {
                font-size: 14px;
                color: #979797;
                line-height: 25px;
            }
        }


        .triangle {
            position: absolute;
            transition: .3s;
            opacity: 0;
            left: 10%;
            bottom: 0;
            width: 0;
            height: 0;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            z-index: 9;
        }

        .triangle::before {
            content: "";
            transition: .3s;
            position: absolute;
            opacity: 0;
            top: 2px;
            transform: translate(-50%, 0);
            width: 0;
            height: 0;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            z-index: 10;
        }

        .triangle::after {
            content: "";
            transition: .3s;
            position: absolute;
            opacity: 0;
            top: 5px;
            transform: translate(-50%, 0);
            width: 0;
            height: 0;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            z-index: 11;
        }


    }

    .list-item::after {
        content: "";
        transition: .3s;
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #3A8DA6;
        opacity: 0;
    }

    .list-item:hover::after {
        opacity: 1;
    }

    .list-item:hover {

        .triangle {
            opacity: 1;
            border-bottom: 10px solid #3A8DA6;
        }

        .triangle::before {
            opacity: 1;
            top: 2.5px;
            transform: translate(-50%, -50%);
            border-bottom: 8px solid #ffffff;
        }

        .triangle::after {
            opacity: 1;
            top: 5px;
            transform: translate(-50%, -50%);
            border-bottom: 5px solid #3A8DA6;
        }

    }
}

// flex布局占位
.list-box::after {
    content: "";
    width: 30%;
    height: 0;
    display: block;
}
</style>